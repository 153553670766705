// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-demo-demo-page-js": () => import("./../../../src/pages/demo/DemoPage.js" /* webpackChunkName: "component---src-pages-demo-demo-page-js" */),
  "component---src-pages-demo-index-js": () => import("./../../../src/pages/demo/index.js" /* webpackChunkName: "component---src-pages-demo-index-js" */),
  "component---src-pages-demo-video-index-js": () => import("./../../../src/pages/demo_video/index.js" /* webpackChunkName: "component---src-pages-demo-video-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-faq-sullen-index-js": () => import("./../../../src/pages/faq/sullen/index.js" /* webpackChunkName: "component---src-pages-faq-sullen-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-index-js": () => import("./../../../src/pages/integrations/index.js" /* webpackChunkName: "component---src-pages-integrations-index-js" */),
  "component---src-pages-integrations-klaviyo-index-js": () => import("./../../../src/pages/integrations/klaviyo/index.js" /* webpackChunkName: "component---src-pages-integrations-klaviyo-index-js" */),
  "component---src-pages-integrations-mailchimp-index-js": () => import("./../../../src/pages/integrations/mailchimp/index.js" /* webpackChunkName: "component---src-pages-integrations-mailchimp-index-js" */),
  "component---src-pages-integrations-typeform-index-js": () => import("./../../../src/pages/integrations/typeform/index.js" /* webpackChunkName: "component---src-pages-integrations-typeform-index-js" */),
  "component---src-pages-integrations-wisepops-index-js": () => import("./../../../src/pages/integrations/wisepops/index.js" /* webpackChunkName: "component---src-pages-integrations-wisepops-index-js" */),
  "component---src-pages-integrations-yotpo-index-js": () => import("./../../../src/pages/integrations/yotpo/index.js" /* webpackChunkName: "component---src-pages-integrations-yotpo-index-js" */),
  "component---src-pages-integrations-zapier-index-js": () => import("./../../../src/pages/integrations/zapier/index.js" /* webpackChunkName: "component---src-pages-integrations-zapier-index-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-who-are-we-index-js": () => import("./../../../src/pages/whoAreWe/index.js" /* webpackChunkName: "component---src-pages-who-are-we-index-js" */),
  "component---src-pages-why-influencer-bit-index-js": () => import("./../../../src/pages/whyInfluencerBit/index.js" /* webpackChunkName: "component---src-pages-why-influencer-bit-index-js" */)
}

